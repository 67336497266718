import { Button, Input, Select, Modal, SelectProps } from 'antd';

import React, { useEffect, useState } from 'react';

import { CopyInput } from '../Inputs';
import { getReviewers } from '~/api/AuthorizedGets';

type ShareProps = {
  isOpen: boolean;
  onCancel: () => void;
  onOK: () => void;
  isAuthenticated: boolean;
  emails: string[];
  onEmailsChange: (emails: string[]) => void;
  onMessageChange: (message: string) => void;
};

export const TenantShareModal = ({
  isOpen,
  onCancel,
  onOK,
  isAuthenticated,
  emails,
  onEmailsChange,
  onMessageChange,
}: ShareProps) => {
  const [userlist, setuserlist] = useState<SelectProps['options']>([]);
  useEffect(() => {
    const getReviewersData = async () => {
      const response = await getReviewers();

      setuserlist(response);
    };

    if (isOpen) {
      getReviewersData();
    }
  }, [isOpen]);

  const filterOption = (input: string, option?: any) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
  return (
    <Modal
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="ok" type="primary" onClick={onOK}>
          {isAuthenticated ? 'Share' : 'Ok'}
        </Button>,
      ]}
      title={`Share ${isAuthenticated ? 'via email' : 'via link'}`}
      open={isOpen}
      onCancel={onCancel}
      onOk={onOK}
    >
      {isAuthenticated ? (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Select Emails"
            optionLabelProp="label"
            options={userlist?.map((userlist) => ({
              label: userlist.email,
              value: userlist.email,
              desc: `${userlist.name} - ${userlist.email}`,
            }))}
            optionRender={(option) => option.data.desc}
            filterOption={filterOption}
            onSelect={(value) => {
              //   if (selectedemails.length >= 3) {
              //     message.error('You can only select up to 3 email');
              //     return;
              //   }

              onEmailsChange([...emails, value]);
            }}
            onDeselect={(value) => onEmailsChange(emails.filter((reviewer) => reviewer !== value))}
            value={emails}
          />

          <Input.TextArea placeholder="Message" onChange={(e) => onMessageChange(e.target.value)} />
        </div>
      ) : (
        <CopyInput />
      )}
    </Modal>
  );
};
